var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"aw-inspirations wrapper"},[_c('div',{staticClass:"aw-inspirations__heading pb-m mb-xl"},[_c('GTitle',{staticClass:"aw-inspirations__heading__title h1",attrs:{"content":_vm.content.title,"tag":"h2"}}),_c('div',{staticClass:"aw-inspirations__heading__text",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}})],1),(_vm.content.items)?_c('UiMasonry',{ref:"masonryRef",attrs:{"items":_vm.content.items.slice(0, _vm.numberToDisplayRef),"keyProp":"src"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"card-inspirations"},[_c('div',{staticClass:"card-inspiration-btn"},[_c('GAction',{staticClass:"card-inspirations-button",attrs:{"content":{
              url: item.link.url,
              label: item.link.label,
              modifiers: ['btn', 'filled'],
              icon: 'arrow',
            }}})],1),_c('figure',{staticClass:"card-inspirations-profile"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(item.profilePicture),expression:"item.profilePicture"}],attrs:{"sets":['160', '240'],"sizes":"(min-width: 960px) 30vw, (min-width: 480px) 50vw, 100vw"}})]),_c('div',{staticClass:"card-inspirations-picture"},[_c('figure',{staticClass:"inspirations-picture-front aw-inspirations__item"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(item.frontPicture),expression:"item.frontPicture"}],attrs:{"sets":['160', '240', '320', '480'],"sizes":"(min-width: 960px) 30vw, (min-width: 480px) 50vw, 100vw"}})]),_c('figure',{staticClass:"inspirations-picture-back aw-inspirations__item"},[_c('img',{directives:[{name:"src",rawName:"v-src",value:(item.backPicture),expression:"item.backPicture"}],attrs:{"sets":['160', '240', '320', '480'],"sizes":"(min-width: 960px) 30vw, (min-width: 480px) 50vw, 100vw"}})])])])]}}],null,false,4209925700)}):_vm._e(),(_vm.numberToDisplayRef <= _vm.content.items.length)?_c('GAction',{staticClass:"aw-inspirations__button mt-m",class:{ 'is-hidden': _vm.numberToDisplayRef >= _vm.content.items.length },attrs:{"content":{
      tag: 'button',
      label: _vm.content.seeMore,
      modifiers: ['btn'],
    }},nativeOn:{"click":function($event){return _vm.onSeeMore.apply(null, arguments)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }